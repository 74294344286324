<template>
  <div>
    <page-header class="mb-2" :hide-message="!showIntro">
      <template #title>
        {{ $t('BecomeMentee') }}
      </template>
      <template #message v-if="showIntro">
        {{ $t('MenteeRegistrationIntroMessage') }}
      </template>
    </page-header>
    <mentee-registration-intro
      v-if="showIntro"
      @show-form="showFormIfCanProceed"
    />
    <template v-else>
      <form-wizard
        color="#00aab5"
        :title="null"
        :subtitle="null"
        :back-button-text="$t('Back')"
        :next-button-text="$t('Next')"
        class="steps-transparent mb-2 mentee-registration"
        @on-change="scrollToTop"
      >
        <tab-content
          class="mb-2"
          :title="$t('AccountDetails')"
          icon="feather icon-user"
          :before-change="validatePersonalDetailsStep"
        >
          <personal-details ref="personalDetailsStep" />
        </tab-content>
        <tab-content
          class="mb-2"
          :title="$t('ProfessionalDetails')"
          icon="feather icon-briefcase"
          :before-change="validateProfessionalDetailsStep"
        >
          <professional-details ref="professionalDetailsStep" showMenteeFields />
        </tab-content>
        <tab-content
          class="mb-2"
          :title="$t('SignInDetails')"
          icon="feather icon-lock"
          :before-change="validateSignInDetailsStep"
        >
          <sign-in-details ref="signInDetailsStep" showMenteeFields />
        </tab-content>
        <template #finish>
          <b-button variant="primary" @click="formSubmitted" :disabled="submitInProgress">
            {{ $t('SubmitApplication') }}
          </b-button>
        </template>
      </form-wizard>
      <required-field-explanation />
    </template>
    <create-mentee-profile-as-mentor-modal from-mentee-registration ref="create-mentee-profile-as-mentor-modal" v-if="isUserLoggedIn" />
    <switch-to-mentee-profile-modal from-mentee-registration ref="switch-to-mentee-profile-modal" v-if="isUserLoggedIn" />
  </div>
</template>

<script>
import {
  BButton
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'
import MenteeRegistrationIntro from '@mentoring-platform/views/components/blocks/Register/MenteeRegistrationIntro.vue'
import PersonalDetails from '@mentoring-platform/views/components/blocks/PersonalDetails.vue'
import ProfessionalDetails from '@mentoring-platform/views/components/blocks/ProfessionalDetails.vue'
import SignInDetails from '@mentoring-platform/views/components/blocks/Register/SignInDetails.vue'
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'
import LoginMixin from '@mentoring-platform/mixins/LoginMixin'
import ScrollToTopMixin from '@mentoring-platform/mixins/ScrollToTopMixin'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import CreateMenteeProfileAsMentorModal from '@mentoring-platform/views/components/blocks/CreateMenteeProfileAsMentorModal.vue'
import SwitchToMenteeProfileModal from '@mentoring-platform/views/components/blocks/SwitchToMenteeProfileModal.vue'

export default {
  mixins: [
    LoginMixin,
    ScrollToTopMixin
  ],
  components: {
    BButton,
    CreateMenteeProfileAsMentorModal,
    FormWizard,
    MenteeRegistrationIntro,
    PageHeader,
    PersonalDetails,
    ProfessionalDetails,
    RequiredFieldExplanation,
    SignInDetails,
    SwitchToMenteeProfileModal,
    TabContent,
  },
  data() {
    return {
      showIntro: true,
      submitInProgress: false
    }
  },
  computed: {
    ...mapGetters('user', {
      'isUserLoggedIn': 'isUserLoggedIn',
      'hasMenteeAccount': 'getHasMenteeAccount' 
    })
  },
  methods: {
    showFormIfCanProceed() {
      if(!this.isUserLoggedIn) {
        this.showForm()
      }
      else if(!this.hasMenteeAccount) {
        this.$refs['create-mentee-profile-as-mentor-modal'].show()
      }
      else {
        this.$refs['switch-to-mentee-profile-modal'].show()
      }
    },
    showForm() {
      this.showIntro = false
    },
    validatePersonalDetailsStep() {
      const isValid = this.$refs.personalDetailsStep.isValid()
      if (isValid) {
        this.$bus.$emit('mentee-registration-first-step-email-filled', this.$refs.personalDetailsStep.collectData().email)
      }
      return isValid
    },
    validateProfessionalDetailsStep() {
      return this.$refs.professionalDetailsStep.isValid()
    },
    validateSignInDetailsStep() {
      return this.$refs.signInDetailsStep.isValid()
    },
    collectData() {
      return {
        ...this.$refs.personalDetailsStep.collectData(),
        ...this.$refs.professionalDetailsStep.collectData(),
        ...this.$refs.signInDetailsStep.collectData(),
        locale: this.$i18n.locale
      }
    },
    async formSubmitted() {
      const isValid = await this.validateSignInDetailsStep()
      if(!isValid) {
        return
      }
      this.submitInProgress = true
      const data = this.collectData()
      const registered = await this.$store.dispatch('user/menteeRegister', data)
      if(registered) {
        this.submitInProgress = false
        this.$root.$bvToast.toast(this.$t('Your mentee application has been registered.'), {
          title: this.$t('Congratulations!'),
          variant: 'success',
          solid: true,
        })
        this.signInEmail = data.email;
        this.password = data.password;
        this.submitLoginData()
      }
      else {
        this.submitInProgress = false
        this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
          title: this.$t('Caution'),
          variant: 'danger',
          solid: true,
        })
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mentee-registration{
  .wizard-tab-content, .wizard-card-footer {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .wizard-card-footer {
    padding-bottom: 30px;
  }
}
</style>
