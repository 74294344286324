<template>
  <div>
    <b-row>
      <b-col
        xs="12"
        md="8"
      >
        <b-card class="p-2">
          <b-card-title class="font-weight-bolder">
            {{ $t('What is mentoring?') }}
          </b-card-title>
          <b-card-text class="pl-1">
            {{ $t('What is mentoring - response') }}
          </b-card-text>
          <b-card-title class="font-weight-bolder">
            {{ $t('What we ask for from a mentee?') }}
          </b-card-title>
          <ul>
            <li>
              <b-link :to="{ name: 'our-mentors' }">
                {{ $t('What we ask for from a mentee - 1') }}
              </b-link>
            </li>
            <li>{{ $t('What we ask for from a mentee - 2') }}</li>
            <li>{{ $t('What we ask for from a mentee - 3') }}</li>
            <li>{{ $t('What we ask for from a mentee - 4') }}</li>
            <li>{{ $t('What we ask for from a mentee - 5') }}</li>
          </ul>
          <p>
            <b-link :to="{ name: 'pages-knowledge-base-category', params: { category: 'for-mentees' } }">
              {{ $t('Find more information here.') }}
            </b-link>
          </p>
          <b-card-title class="font-weight-bolder">
            {{ $t('You have not decided yet for which field you interested in?') }}
          </b-card-title>
          <b-card-text>
            <b-link :to="{ name: 'mentoring-packages' }">
              {{ $t('Discover more about the 2-in-1 Coaching & Mentoring package!') }}
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-card
          no-body
          :img-src="require('@/assets/images/pages/Become_Mentee_F.jpg')"
          img-alt="Card image shoes"
          img-top
        >
          <b-card-body>
            <b-card-title>
              {{ $t('Do you want to become a mentee?') }}
            </b-card-title>
            <b-card-text>
              {{ $t('Register and find your mentor') }}
            </b-card-text>
            <b-button
              variant="primary"
              @click="showForm"
            >
              {{ $t('Register now') }} &gt;
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BLink,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BCol,
    BLink,
    BRow,
  },
  data() {
    return {

    }
  },
  methods: {
    showForm() {
      this.$emit('show-form')
    }
  }
}
</script>

<style scoped>

</style>
